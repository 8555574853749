.not-found {
  padding: 10rem 0 8rem;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  color: white;
  line-height: 1;
}

.not-found .sub-heading {
  text-transform: uppercase;
  font-size: 20rem;
}

/* making the font-size of sub-heading fit smaller screens */
@media (max-width: 768px) {
  .not-found .sub-heading {
    font-size: 5rem;
  }
}

.not-found__btn {
  cursor: pointer;
}
