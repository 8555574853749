.flow-content > * + * {
  margin-top: 10px;
}

.policy-container {
  position: relative;
  padding: 10rem 0 8rem;
  color: #9ca9b3;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto;
}

@media only screen and (max-width: 1024px) {
  .policy-container {
    padding: 4rem 0 4rem;
  }
}

.policy-container h4 {
  font-size: 22px;
  text-transform: capitalize;
  color: #5658dd;
}

@media screen and (max-width: 414px) {
  .policy-container h4 {
    padding: 1.5rem 0;
  }
}

.policy-container h5 {
  font-size: 20px;
  text-transform: capitalize;
  color: #5658dd;
}

@media screen and (max-width: 414px) {
  .policy-container h4 {
    padding: 1.4rem 0;
  }
}

.policy-container p {
  font-size: 21px;
  line-height: 1.41;
}

li {
  font-size: 21px;
  line-height: 1.41;
  margin-bottom: 1em;
}
